//import {createRouter, createWebHistory} from "vue-router";
import {createRouter, createWebHashHistory} from "vue-router";
 
const Login = () => import('../views/Login')
const Card = () => import('../views/Card')
const Onetime = () => import('../views/Onetime')
const End = () => import('../views/End')
 
const routes = [
    {
        Path: '',
        redirect: '/home'
    },
    {
        path: '/home',
        component: Login,
		meta:{
			title:"ログイン | イオンカード　暮らしのマネーサイト"
		}
    },
	{
        path: '/card',
        component: Card,
		meta:{
			title:"会員情報の入力 | イオンカード　暮らしのマネーサイト"
		}
    },
	{
        path: '/onetime',
        component: Onetime,
		meta:{
			title:"ワンタイムパスワード認証 | イオンカード　暮らしのマネーサイト"
		}
    },
	{
        path: '/end',
        component: End,
		meta:{
			title:"お手続きの完了 | イオンカード　暮らしのマネーサイト"
		}
    },
]
 
const router = createRouter({
    //history: createWebHistory(),
	history: createWebHashHistory('/track/'),
    routes
})

router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
})

export default router